<title *ngIf="advertisement">Auto-net - {{advertisement.vehicle.make}} {{advertisement.vehicle.model}}
  {{advertisement.vehicle.type}}</title>

<!-- Page content-->
<div class="container pt-5">
  <div class="container rounded bg-white mt-5 mb-md-4 pt-3 text-center" *ngIf="!advertisement">
    <div class="spinner-border text-primary" role="status" aria-hidden="true"></div>
    <p class="mt-2">{{ 'advertisement-detail.loading' | translate }}</p>
  </div>

  <div class="container rounded bg-white mt-5 mb-md-4 pt-3" *ngIf="advertisement">
    <!-- Title -->

    <div class="d-sm-flex align-items-end align-items-md-center justify-content-between position-relative mb-4"
      style="z-index: 1025;">
      <div class="me-3">
        <h1 class="h4 text mb-md-0"> {{advertisement.vehicle.make}} {{advertisement.vehicle.model}}
          {{advertisement.vehicle.type}}</h1>
      </div>

      <div class="align-items-center text  d-block d-sm-none">
        <div *ngIf="advertisement.consumerPrice !== null">
          <div class="h3 mb-2 mt-2">
            <span class="text-muted text-decoration-line-through" *ngIf="advertisement.consumerPrice.formerAmount != null">
              {{advertisement.consumerPrice.formerAmount | currencyFormat}}
            </span>
            <span *ngIf="advertisement.consumerPrice.formerAmount == null">
              {{advertisement.consumerPrice.amount | currencyFormat}}
            </span>
            <span class="text-success ms-2" *ngIf="advertisement.consumerPrice.formerAmount != null">
              {{advertisement.consumerPrice.amount | currencyFormat}}
            </span>
          </div>
        </div>
        
        <span class="h3 text-danger mb-2 mt-2" *ngIf="advertisement.consumerPrice == null">
          {{ 'advertisement-list.sold' | translate }}
        </span>
        
        <div class="d-flex justify-content-between align-items-center">
          <div class="d-flex align-items-center">
            <i class="fi-dashboard fs-lg opacity-70 me-2"></i>
            <span class="align-middle">{{advertisement.vehicle.odometer | number}}
              {{advertisement.vehicle.odometerUnit}}</span>
          </div>
          <div *ngIf="advertisement.vehicle.hasValidOdometer === true">
            <img class="d-table mb-2 bg-white rounded" src="../../../assets/images/nap-check.png" alt="NAP Check"
              width="60">
          </div>
        </div>
      </div>

      <!-- Buttons with responsive alignment and full width on mobile -->
      <div
        class="col-lg-4 col-md-5 col-sm-6 d-flex justify-content-lg-end justify-content-md-end justify-content-center">
        <div class="btn-group w-100" role="group">
          <button (click)="previousAdvertisement()" type="button" class="btn btn-primary btn-sm w-100 mb-2 mb-sm-0">
            {{ 'advertisement-detail.previous' | translate }}
          </button>
          <button (click)="backToOverview()" type="button" class="btn btn-secondary btn-sm w-100 mb-2 mb-sm-0">
            {{ 'advertisement-detail.overview' | translate }}
          </button>
          <button (click)="nextAdvertisement()" type="button" class="btn btn-primary btn-sm w-100 mb-2 mb-sm-0">
            {{ 'advertisement-detail.next' | translate }}
          </button>
        </div>
      </div>
    </div>

    <div class="row">
      <div class="col-md-7">
        <!-- Gallery-->
        <ks-carousel [config]="config" [id]="101" [images]="images"></ks-carousel>

        <!-- Features-->
        <div class="accordion">
          <div ngbAccordion class="rounded" [closeOthers]="false">
            <div ngbAccordionItem [collapsed]="false">
              <h2 ngbAccordionHeader class="accordion-header" id="gen-ques-headingOne" data-bs-toggle="collapse"
                data-bs-target="#gen-ques-collapseOne" aria-expanded="false" aria-controls="gen-ques-collapseOne">
                <button ngbAccordionButton>{{ 'advertisement-detail.optionsAndAccessories' | translate }}</button>
              </h2>
              <div ngbAccordionCollapse>
                <div ngbAccordionBody>
                  <ng-template>
                    <div class="container">
                      <div class="row">
                        <!-- First Column for desktop, full width for mobile -->
                        <div class="col-12 col-md-6">
                          <div class="d-flex flex-wrap">
                            <ng-container *ngFor="let accessory of advertisement.vehicle.accessories; let i = index">
                              <span *ngIf="i % 2 === 0" class="badge bg-secondary me-2 mb-2">
                                {{ accessory }}
                              </span>
                            </ng-container>
                          </div>
                        </div>
                        <!-- Second Column for desktop, full width for mobile -->
                        <div class="col-12 col-md-6">
                          <div class="d-flex flex-wrap">
                            <ng-container *ngFor="let accessory of advertisement.vehicle.accessories; let i = index">
                              <span *ngIf="i % 2 !== 0" class="badge bg-secondary me-2 mb-2">
                                {{ accessory }}
                              </span>
                            </ng-container>
                          </div>
                        </div>
                      </div>
                    </div>
                  </ng-template>
                </div>
              </div>
            </div>

            <!-- Comments-->
            <div class="accordion" *ngIf="vehicleComments.length > 0">
              <div ngbAccordion class="rounded" [closeOthers]="false">
                <div ngbAccordionItem [collapsed]="false">
                  <h2 ngbAccordionHeader class="accordion-header" id="gen-ques-headingOne" data-bs-toggle="collapse"
                    data-bs-target="#gen-ques-collapseOne" aria-expanded="false" aria-controls="gen-ques-collapseOne">
                    <button ngbAccordionButton>{{ 'advertisement-detail.comments' | translate }}</button>
                  </h2>
                  <div ngbAccordionCollapse>
                    <div ngbAccordionBody>
                      <ng-template>
                        <div class="container">
                          <div class="row">
                            <ul>
                              <ng-container *ngFor="let comment of vehicleComments">
                                <li>
                                  {{comment.comment}}
                                </li>
                              </ng-container>
                            </ul>
                          </div>
                        </div>
                      </ng-template>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div ngbAccordionItem [collapsed]="false">
              <h2 ngbAccordionHeader class="accordion-header" id="gen-ques-headingOne" data-bs-toggle="collapse"
                data-bs-target="#gen-ques-collapseOne" aria-expanded="false" aria-controls="gen-ques-collapseOne">
                <button ngbAccordionButton>{{ 'advertisement-detail.tradeInYourCar' | translate }}</button>
              </h2>
              <div ngbAccordionCollapse>
                <div ngbAccordionBody>
                  <ng-template>
                    <div class="row">
                      <div class="col-md-8">
                        <h3>{{ 'advertisement-detail.howMuchToPay' | translate }}</h3>
                        <ul class="list-unstyled">
                          <li><i class="fi fi-check"></i> {{ 'advertisement-detail.freeTradeInProposal' | translate }}
                          </li>
                          <li><i class="fi fi-check"></i> {{ 'advertisement-detail.receiveTradeInProposal' | translate
                            }}</li>
                          <li><i class="fi fi-check"></i> {{ 'advertisement-detail.noObligations' | translate }}</li>
                        </ul>
                        <button (click)="startRequest()" type="button" class="btn btn-primary">{{
                          'advertisement-detail.requestTradeInProposal' | translate }}</button>
                      </div>
                      <div class="col-md-4">
                        <img src="../../../assets/images/tradecar.jpg" alt="Car" class="img-fluid">
                      </div>
                    </div>
                  </ng-template>
                </div>
              </div>
            </div>

            <div ngbAccordionItem [collapsed]="false">
              <h2 ngbAccordionHeader class="accordion-header" id="gen-ques-headingOne" data-bs-toggle="collapse"
                data-bs-target="#gen-ques-collapseOne" aria-expanded="false" aria-controls="gen-ques-collapseOne">
                <button ngbAccordionButton>{{ 'advertisement-detail.history' | translate }}</button>
              </h2>
              <div ngbAccordionCollapse>
                <div ngbAccordionBody>
                  <ng-template>
                    <div class="row">
                      <div class="col-sm-12 col-md-12 col-lg-10">
                        <ul class="list-unstyled">
                          <li class="row">
                            <strong class="col">{{ 'advertisement-detail.newPriceFrom' | translate }}</strong>
                            <span class="col text-end opacity-70"
                              *ngIf="advertisement.consumerPrice !== null">{{advertisement.consumerPrice.amount |
                              currencyFormat}}</span>
                          </li>
                          <li class="row">
                            <strong class="col">{{ 'advertisement-detail.buildYear' | translate }}</strong>
                            <span class="col text-end opacity-70">{{advertisement.vehicle.buildYear}}</span>
                          </li>
                          <li class="row">
                            <strong class="col">{{ 'advertisement-detail.mileage' | translate }}</strong>
                            <span class="col text-end opacity-70">{{advertisement.vehicle.odometer | number}}
                              {{advertisement.vehicle.odometerUnit}}</span>
                          </li>
                          <li class="row">
                            <strong class="col">{{ 'advertisement-detail.napLabel' | translate }}</strong>
                            <span class="col text-end opacity-70">{{advertisement.vehicle.hasValidOdometer |
                              yesNo}}</span>
                          </li>
                          <li class="row">
                            <strong class="col">{{ 'advertisement-detail.firstOwner' | translate }}</strong>
                            <span class="col text-end opacity-70">{{advertisement.vehicle.isFirstOwner | yesNo}}</span>
                          </li>
                          <li class="row">
                            <strong class="col">{{ 'advertisement-detail.dateOfFirstAdmission' | translate }}</strong>
                            <span class="col text-end opacity-70">{{advertisement.vehicle.dateFirstAdmission}}</span>
                          </li>
                          <li class="row">
                            <strong class="col">{{ 'advertisement-detail.dateOfFirstRegistrationNL' | translate
                              }}</strong>
                            <span class="col text-end opacity-70">{{advertisement.vehicle.dateFirstRegistration}}</span>
                          </li>
                          <li class="row">
                            <strong class="col">{{ 'advertisement-detail.apkValidUntil' | translate }}</strong>
                            <span class="col text-end opacity-70">{{advertisement.vehicle.dateApkExpires}}</span>
                          </li>
                        </ul>
                      </div>
                    </div>
                  </ng-template>
                </div>
              </div>
            </div>

            <div ngbAccordionItem [collapsed]="false">
              <h2 ngbAccordionHeader class="accordion-header" id="gen-ques-headingOne" data-bs-toggle="collapse"
                data-bs-target="#gen-ques-collapseOne" aria-expanded="false" aria-controls="gen-ques-collapseOne">
                <button ngbAccordionButton>{{ 'advertisement-detail.environment' | translate }}</button>
              </h2>
              <div ngbAccordionCollapse>
                <div ngbAccordionBody>
                  <ng-template>
                    <div class="row">
                      <div class="col-sm-12 col-md-12 col-lg-10">
                        <ul class="list-unstyled">
                          <li class="row">
                            <strong class="col">{{ 'advertisement-detail.consumptionCity' | translate }}</strong>
                            <span class="col text-end opacity-70">{{advertisement.vehicle.usageCity}} L/100km</span>
                          </li>
                          <li class="row">
                            <strong class="col">{{ 'advertisement-detail.consumptionHighway' | translate }}</strong>
                            <span class="col text-end opacity-70">{{advertisement.vehicle.usageHighway}} L/100km</span>
                          </li>
                          <li class="row">
                            <strong class="col">{{ 'advertisement-detail.averageConsumption' | translate }}</strong>
                            <span class="col text-end opacity-70">{{advertisement.vehicle.usageAverage}} L/100km</span>
                          </li>
                        </ul>
                      </div>
                    </div>
                  </ng-template>
                </div>
              </div>
            </div>

            <div ngbAccordionItem [collapsed]="false">
              <h2 ngbAccordionHeader class="accordion-header" id="gen-ques-headingOne" data-bs-toggle="collapse"
                data-bs-target="#gen-ques-collapseOne" aria-expanded="false" aria-controls="gen-ques-collapseOne">
                <button ngbAccordionButton>{{ 'advertisement-detail.guarantees' | translate }}</button>
              </h2>
              <div ngbAccordionCollapse>
                <div ngbAccordionBody>
                  <ng-template>
                    <ul class="list-unstyled">
                      <li *ngIf="advertisement.vehicle.hasValidOdometer"><i class="fi fi-check"></i>&nbsp;{{
                        'advertisement-detail.logicalMileage' | translate }}</li>
                      <li *ngIf="advertisement.vehicle.numberOfOwners <= 2"><i class="fi fi-check"></i>&nbsp;{{
                        'advertisement-detail.ownerText' | translate }}
                        {{advertisement.vehicle.numberOfOwners}}<sup>{{'advertisement-detail.th' | translate}}</sup>
                        {{'advertisement-detail.owner' |
                        translate}}</li>
                      <li *ngIf="!advertisement.vehicle.isImport"><i class="fi fi-check"></i>&nbsp;{{
                        'advertisement-detail.officialImport' | translate }}</li>
                    </ul>
                  </ng-template>
                </div>
              </div>
            </div>

            <div ngbAccordionItem [collapsed]="false">
              <h2 ngbAccordionHeader class="accordion-header" id="gen-ques-headingOne" data-bs-toggle="collapse"
                data-bs-target="#gen-ques-collapseOne" aria-expanded="false" aria-controls="gen-ques-collapseOne">
                <button ngbAccordionButton>{{ 'advertisement-detail.calculateVehicleTax' | translate }}</button>
              </h2>
              <div ngbAccordionCollapse>
                <div ngbAccordionBody>
                  <ng-template>
                    <form [formGroup]="vehicleOwnershipTaxForm">
                      <div class="mb-3">
                        <label for="province" class="form-label">{{ 'advertisement-detail.selectProvince' | translate
                          }}</label>
                        <select (change)="submitOwnershipTaxForm()" formControlName="province" id="province"
                          class="form-select">
                          <option value="null">{{ 'advertisement-detail.selectProvince' | translate }}</option>
                          <option value="drenthe">Drenthe</option>
                          <option value="flevoland">Flevoland</option>
                          <option value="friesland">Friesland</option>
                          <option value="gelderland">Gelderland</option>
                          <option value="groningen">Groningen</option>
                          <option value="limburg">Limburg</option>
                          <option value="noord-brabant">Noord Brabant</option>
                          <option value="noord-holland">Noord Holland</option>
                          <option value="overijssel">Overijssel</option>
                          <option value="utrecht">Utrecht</option>
                          <option value="zeeland">Zeeland</option>
                          <option value="zuid-holland">Zuid Holland</option>
                        </select>
                      </div>
                    </form>

                    <div class="card mt-2" *ngIf="ownershipTax">
                      <div class="card-header">
                        {{ 'advertisement-detail.calculationResult' | translate }}
                      </div>
                      <div class="card-body">
                        <table class="table">
                          <tbody>
                            <tr>
                              <th>{{ 'advertisement-detail.amountPerMonth' | translate }}</th>
                              <td>{{ ownershipTax.vehicleOwnershipTaxPerMonth | currencyFormat }}</td>
                            </tr>
                            <tr>
                              <th>{{ 'advertisement-detail.amountPerQuarter' | translate }}</th>
                              <td>{{ ownershipTax.vehicleOwnershipTaxPerQuarter | currencyFormat }}</td>
                            </tr>
                            <tr>
                              <th>{{ 'advertisement-detail.amountPerYear' | translate }}</th>
                              <td>{{ ownershipTax.vehicleOwnershipTaxPerYear | currencyFormat }}
                              </td>
                            </tr>
                          </tbody>
                        </table>
                      </div>
                    </div>
                  </ng-template>
                </div>
              </div>
            </div>

            <div ngbAccordionItem [collapsed]="true">
              <h2 ngbAccordionHeader class="accordion-header" id="gen-ques-headingOne" data-bs-toggle="collapse"
                data-bs-target="#gen-ques-collapseOne" aria-expanded="false" aria-controls="gen-ques-collapseOne">
                <button ngbAccordionButton>{{ 'advertisement-detail.disclaimer' | translate }}</button>
              </h2>
              <div ngbAccordionCollapse>
                <div ngbAccordionBody>
                  <ng-template>
                    <p>{{ 'advertisement-detail.disclaimerText' | translate }}</p>
                  </ng-template>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <!-- Sidebar-->
      <div class="col-md-5 pt-5 pt-md-0" style="margin-top: -6rem;" *ngIf="advertisement">
        <div class="sticky-top pt-5">
          <div class="d-none d-md-block pt-5">
            <div *ngIf="advertisement.consumerPrice !== null">
              <div class="h3 text-primary" *ngIf="advertisement.consumerPrice.formerAmount == null">
                {{advertisement.consumerPrice.amount | currencyFormat}}
              </div>
              <div class="h3 text-muted" *ngIf="advertisement.consumerPrice.formerAmount != null">
                <span class="text-decoration-line-through">{{advertisement.consumerPrice.formerAmount | currencyFormat}}</span>
              <span class="h3 text-success" *ngIf="advertisement.consumerPrice.formerAmount != null">
                {{advertisement.consumerPrice.amount | currencyFormat}}
                </span>
              </div>
            </div>
            
            <div class="h3 text-danger" *ngIf="advertisement.consumerPrice == null">
              {{ 'advertisement-list.sold' | translate }}
            </div>

            <div class="d-flex align-items-center text pb-4 mb-2">
              <div class="text-nowrap border-end border-light pe-3 me-3"><i
                  class="fi-dashboard fs-lg opacity-70 me-2"></i><span
                  class="align-middle">{{advertisement.vehicle.odometer | number}}
                  {{advertisement.vehicle.odometerUnit}}</span></div>

              <div class="me-4 mt-1" *ngIf="advertisement.vehicle.hasValidOdometer === true">
                <img class="d-table mb-1 bg-white rounded" src="../../../assets/images/nap-check.png" alt="NAP Check"
                  width="60">
              </div>

              <div class="d-flex justify-content-end">
                <div class="btn-group" role="group">
                  <button type="button" class="btn btn-secondary btn-sm" (click)="print()">{{
                    'advertisement-detail.print' | translate }}</button>
                </div>
              </div>
            </div>
          </div>

          <div class="card mb-4 shadow-sm">
            <div class="card-header bg-primary text-white">
              {{ 'advertisement-detail.reactNow' | translate }}
            </div>
            <div class="card-body">
              <div class="d-flex align-items-center mb-2">
                <i class="fi fi-rr-refresh mr-2"></i>
                <a (click)="startRequest()" href="javascript:void(0);" class="font-weight-bold">{{
                  'advertisement-detail.requestTradeInProposalLink' | translate }}</a>
              </div>
              <div class="d-flex align-items-center mb-2">
                <span class="font-weight-bold"><b>{{ 'advertisement-detail.contactUs' | translate }}</b></span>
              </div>
              <div class="d-flex align-items-center mb-2">
                <i class="fi fi-mail mr-4"></i>&nbsp;
                <a href="mailto:verkoop@auto-net.nl?SUBJECT=Vraag via website over {{advertisement.vehicle.make}} {{advertisement.vehicle.model}} ({{advertisement.vehicle.licensePlate}})"
                  class="font-weight-bold">{{
                  'advertisement-detail.contactMail' | translate }}</a>
              </div>
              <div class="d-flex align-items-center mb-2">
                <i class="fi fi-bell mr-4"></i>
                <span class="font-weight-bold">&nbsp;{{ 'advertisement-detail.callUs' | translate }}</span>
              </div>
              <div class="d-flex align-items-center">
                <i class="fi fi-phone mr-2"></i>
                <span class="font-weight-bold">&nbsp;{{'advertisement-detail.general' | translate}}: <a
                    href="tel:+318833250">088 - 88 33 250</a></span>
              </div>
              <div class="d-flex align-items-center">
                <i class="fi fi-phone mr-2"></i>
                <span class="font-weight-bold">&nbsp;Ertjo Buitenhuis JR:  <a href="tel:+31651055825">06 - 510 55
                    825</a></span>
              </div>
            </div>
          </div>

          <div class="card mb-4">
            <div class="card-header bg-primary text-white">
              {{ 'advertisement-detail.details' | translate }}
            </div>
            <div class="card-body">
              <table class="table table-striped table-responsive">
                <tbody>
                  <tr>
                    <th class="text-start align-middle">{{ 'advertisement-detail.licensePlate' | translate }}</th>
                    <td class="text-end align-middle">{{advertisement.vehicle.licensePlate | registration}}</td>
                  </tr>
                  <tr>
                    <th class="text-start align-middle">{{ 'advertisement-detail.mileageWithUnit' | translate }}</th>
                    <td class="text-end align-middle">{{advertisement.vehicle.odometer | number}}
                      {{advertisement.vehicle.odometerUnit}}</td>
                  </tr>
                  <tr>
                    <th class="text-start align-middle">{{ 'advertisement-detail.buildYear' | translate }}</th>
                    <td class="text-end align-middle">{{advertisement.vehicle.buildYear}}</td>
                  </tr>
                  <tr>
                    <th class="text-start align-middle">{{ 'advertisement-detail.apkValidUntil' | translate }}</th>
                    <td class="text-end align-middle">{{advertisement.vehicle.dateApkExpires}}</td>
                  </tr>
                  <tr>
                    <th class="text-start align-middle">{{ 'advertisement-detail.fuelType' | translate }}</th>
                    <td class="text-end align-middle">{{advertisement.vehicle.fuelType}}</td>
                  </tr>
                  <tr>
                    <th class="text-start align-middle">{{ 'advertisement-detail.averageConsumptionWithUnit' | translate
                      }}</th>
                    <td class="text-end align-middle">{{advertisement.vehicle.usageAverage}} L/100KM</td>
                  </tr>
                  <tr>
                    <th class="text-start align-middle">{{ 'advertisement-detail.bodyType' | translate }}</th>
                    <td class="text-end align-middle">{{advertisement.vehicle.bodyType}}</td>
                  </tr>
                  <tr>
                    <th class="text-start align-middle">{{ 'advertisement-detail.numberOfDoors' | translate }}</th>
                    <td class="text-end align-middle">{{advertisement.vehicle.numberOfDoors}}</td>
                  </tr>
                  <tr>
                    <th class="text-start align-middle">{{ 'advertisement-detail.transmission' | translate }}</th>
                    <td class="text-end align-middle">{{advertisement.vehicle.transmissionType}}</td>
                  </tr>
                  <tr>
                    <th class="text-start align-middle">{{ 'advertisement-detail.exteriorColor' | translate }}</th>
                    <td class="text-end align-middle">{{advertisement.vehicle.color}}</td>
                  </tr>
                  <tr>
                    <th class="text-start align-middle">{{ 'advertisement-detail.interiorColor' | translate }}</th>
                    <td class="text-end align-middle">{{advertisement.vehicle.interiorTrimColor}}</td>
                  </tr>
                </tbody>
              </table>
              <a href="mailto:?subject=Aanbevolen auto van auto-net.nl&body=Beste,%0D%0A%0D%0AVanuit auto-net.nl stuur ik je de volgende auto door:%0D%0Ahttps://www.auto-net.nl/occasion/{{advertisement.id}}%0D%0A%0D%0AMet vriendelijke groet,%0D%0A%0D%0A">{{ 'advertisement-detail.sendToFriend' | translate }}</a>
            </div>
          </div>

          <div class="card">
            <div class="card-header bg-primary text-white">
              {{ 'advertisement-detail.reviews' | translate }}
            </div>
            <iframe frameborder="0" width="300"
              src="https://www.klantenvertellen.nl/retrieve-widget.html?color=white&amp;allowTransparency=false&amp;button=true&amp;lang=nl&amp;tenantId=99&amp;locationId=1052512"></iframe>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>

<div #printSection style="display: none;">
  <app-advertisement-print *ngIf="advertisement" [advertisement]="advertisement"></app-advertisement-print>
</div>