<title>Auto-net - {{ 'carInventory.title' | translate }}</title>
<!-- Page content-->
<div class="container pt-5">
  <div class="container rounded bg-white mt-5 mb-md-4 pt-3">
    <div class="row py-md-1">
      <!-- Filers sidebar (Offcanvas on mobile)-->
      <div class="col-lg-3 pe-xl-4">
        <div class="offcanvas offcanvas-start offcanvas-collapse" id="filters-sidebar">
          <div class="offcanvas-header bg-transparent d-flex d-lg-none align-items-center">
            <h2 class="h5 text mb-0">{{ 'advertisement-list.filters.filters' | translate }}</h2>
            <button class="btn-close btn-close" type="button" data-bs-dismiss="offcanvas"></button>
          </div>

          <div class="offcanvas-body">
            <form *ngIf="isSearchFormReady && filteredSearchOptions" [formGroup]="searchForm">

              <h2 class="h5 text mb-0 d-none d-md-block">{{ 'advertisement-list.filters.filters' | translate }}</h2>

              <div class="pt-3 pb-2 mb-4">
                <div class="form-check">
                  <input (change)="filterInStockChange($event)" formControlName="inStock" class="form-check-input"
                    type="checkbox" formControlName="inStock" id="inStock">
                  <label class="form-check-label" for="inStock">{{ 'advertisement-list.filters.vehiclesInStock' |
                    translate }}</label>
                </div>
              </div>

              <div class="border-bottom mb-4"></div>

              <div class="pb-3 mb-4 border-bottom">
                <div class="pt-2 mb-2">
                  <h3 class="h6">{{ 'advertisement-list.filters.price' | translate }}</h3>
                  <div class="d-flex align-items-center mt-3">
                    <div class="w-50 pe-1">
                      <div class="input-group">
                        <span class="input-group-text" style="padding: 0.375rem 0.5rem; font-size: 0.875rem;">€</span>
                        <input formControlName="minPrice" class="form-control range-slider-value-min"
                          min="{{searchOptions.consumerPrice.min}}" max="{{searchOptions.consumerPrice.max}}"
                          type="number" placeholder="{{ 'advertisement-list.filters.minPrice' | translate }}">
                      </div>
                    </div>

                    <div class="text-muted">&mdash;</div>

                    <div class="w-50 ps-1">
                      <div class="input-group">
                        <span class="input-group-text" style="padding: 0.375rem 0.5rem; font-size: 0.875rem;">€</span>
                        <input formControlName="maxPrice" class="form-control range-slider-value-max"
                          min="{{searchOptions.consumerPrice.min}}" max="{{searchOptions.consumerPrice.max}}"
                          type="number" placeholder="{{ 'advertisement-list.filters.maxPrice' | translate }}">
                      </div>
                    </div>
                  </div>

                  <div *ngIf="searchForm.get('minPrice')!.touched && searchForm.get('minPrice')!.invalid"
                    class="text-danger">
                    <div *ngIf="searchForm.get('minPrice')!.errors?.['min']">{{
                      'advertisement-list.filters.minPriceTooLow' |
                      translate }} &euro;{{searchOptions.consumerPrice.min}}</div>
                    <div *ngIf="searchForm.get('minPrice')!.errors?.['max']">{{
                      'advertisement-list.filters.minPriceTooHigh' |
                      translate }} &euro;{{searchOptions.consumerPrice.max}}</div>
                    <div *ngIf="searchForm.get('minPrice')!.errors?.['required']">{{
                      'advertisement-list.filters.minPriceRequired' | translate }}</div>
                  </div>

                  <div *ngIf="searchForm.get('maxPrice')!.touched && searchForm.get('maxPrice')!.invalid"
                    class="text-danger">
                    <div *ngIf="searchForm.get('maxPrice')!.errors?.['min']">{{
                      'advertisement-list.filters.maxPriceTooLow' |
                      translate }} &euro;{{searchOptions.consumerPrice.min}}</div>
                    <div *ngIf="searchForm.get('maxPrice')!.errors?.['max']">{{
                      'advertisement-list.filters.maxPriceTooHigh' |
                      translate }} &euro;{{searchOptions.consumerPrice.max}}</div>
                    <div *ngIf="searchForm.get('maxPrice')!.errors?.['required']">{{
                      'advertisement-list.filters.maxPriceRequired' | translate }}</div>
                  </div>
                </div>
              </div>

              <div class="pb-4 mb-2 simplebar-scroll-show" *ngIf="filteredSearchOptions" formGroupName="makes">
                <h3 class="h6 text">{{ 'advertisement-list.filters.make' | translate }}</h3>
                <ngx-simplebar style="max-height: 21rem;" class="overflow-auto">
                  <div class="form-check" *ngFor="let make of filteredSearchOptions.makes">
                    <input class="form-check-input" type="checkbox" [formControlName]="make.id" id="{{make.id}}"
                      (change)="onCheckboxChange($event, 'makes', make.id, 'Vehicle.makeId')">
                    <label class="form-check-label fs-sm" for="{{make.id}}">{{make.label}}</label>
                  </div>
                </ngx-simplebar>
              </div>

              <div class="pb-4 mb-2 simplebar-scroll-show" *ngIf="filteredSearchOptions" formGroupName="models">
                <h3 class="h6 text">{{ 'advertisement-list.filters.model' | translate }}</h3>
                <ngx-simplebar style="max-height: 21rem;" class="overflow-auto">
                  <div class="form-check" *ngFor="let model of filteredSearchOptions.models">
                    <input class="form-check-input" type="checkbox" [formControlName]="model.id" id="{{model.id}}"
                      (change)="onCheckboxChange($event, 'models', model.id, 'Vehicle.modelId')">
                    <label class="form-check-label fs-sm" for="{{model.id}}">{{model.label}}</label>
                  </div>
                </ngx-simplebar>
              </div>

              <div class="border-bottom mb-4"></div>

              <div class="pb-4 mb-2 simplebar-scroll-show" *ngIf="searchOptions" formGroupName="transmissions">
                <h3 class="h6 text">{{ 'advertisement-list.filters.transmission' | translate }}</h3>
                <ngx-simplebar style="max-height: 4rem;" class="overflow-auto">
                  <div class="form-check" *ngFor="let transmission of searchOptions.transmissionTypes">
                    <input class="form-check-input" type="checkbox" [formControlName]="transmission.id"
                      id="{{transmission.id}}"
                      (change)="onCheckboxChange($event, 'transmissions', transmission.id, 'Vehicle.transmissionTypeId')">
                    <label class="form-check-label fs-sm" for="{{transmission.id}}">{{transmission.label}}</label>
                  </div>
                </ngx-simplebar>
              </div>

              <div class="pb-4 mb-2 simplebar-scroll-show" *ngIf="searchOptions" formGroupName="bodyTypes">
                <h3 class="h6 text">{{ 'advertisement-list.filters.bodyType' | translate }}</h3>
                <ngx-simplebar style="max-height: 5rem;" class="overflow-auto">
                  <div class="form-check" *ngFor="let bodyType of searchOptions.bodyTypes">
                    <input class="form-check-input" type="checkbox" [formControlName]="bodyType.id" id="{{bodyType.id}}"
                      (change)="onCheckboxChange($event, 'bodyTypes', bodyType.id, 'Vehicle.bodyTypeId')">
                    <label class="form-check-label fs-sm" for="{{bodyType.id}}">{{bodyType.label}}</label>
                  </div>
                </ngx-simplebar>
              </div>

              <div class="pb-4 mb-2 simplebar-scroll-show" *ngIf="searchOptions" formGroupName="fuelTypes">
                <h3 class="h6 text">{{ 'advertisement-list.filters.fuelType' | translate }}</h3>
                <ngx-simplebar style="max-height: 5rem;" class="overflow-auto">
                  <div class="form-check" *ngFor="let fuelType of searchOptions.fuelTypes">
                    <input class="form-check-input" type="checkbox" [formControlName]="fuelType.id" id="{{fuelType.id}}"
                      (change)="onCheckboxChange($event, 'fuelTypes', fuelType.id, 'Vehicle.fuelTypeId')">
                    <label class="form-check-label fs-sm" for="{{fuelType.id}}">{{fuelType.label}}</label>
                  </div>
                </ngx-simplebar>
              </div>

              <div class="pb-4 mb-2 simplebar-scroll-show" *ngIf="lastViewedAdvertisement">
                <h3 class="h6 text">{{ 'advertisement-list.filters.recentlyViewed' | translate }}</h3>
                <ngx-simplebar routerLink="/occasion/{{lastViewedAdvertisement.id}}" class="overflow-auto">
                  <h4>{{lastViewedAdvertisement.vehicle.make}} {{lastViewedAdvertisement.vehicle.model}}</h4>
                  <img [src]="lastViewedAdvertisement.images[0]" class="card-img" alt="Image">
                </ngx-simplebar>
              </div>
            </form>
          </div>
        </div>
      </div>

      <!-- Catalog list view-->
      <div class="col-lg-9">
        <!-- Page title-->
        <div class="d-flex align-items-center justify-content-between pb-4 mb-2">
          <h2 class="text me-3 mb-0">{{ 'advertisement-list.title' | translate }}</h2>
          <div *ngIf="advertisementPaginator" class="text"><i class="fi-car fs-lg me-2"></i><span
              class="align-middle">{{advertisementPaginator.totalItems}} {{ 'advertisement-list.results' | translate
              }}</span>
          </div>
        </div>

        <!-- Sorting + View-->
        <div class="d-sm-flex align-items-center justify-content-between pb-4 mb-2">
          <div class="d-flex align-items-center me-sm-4">
            <label class="fs-sm text me-2 pe-1 text-nowrap" for="sorting1">
              <i class="fi-arrows-sort mt-n1 me-2"></i>{{ 'advertisement-list.filters.sort' | translate }}
            </label>
            <select class="form-select form-select-sm me-sm-4" id="sorting1" (change)="sortChange($event)">
              <option value="">{{ 'advertisement-list.filters.select' | translate }}</option>
              <option value="Aprice">{{ 'advertisement-list.filters.priceLowToHigh' | translate }}</option>
              <option value="Dprice">{{ 'advertisement-list.filters.priceHighToLow' | translate }}</option>
              <option value="Amileage">{{ 'advertisement-list.filters.mileageLowToHigh' | translate }}</option>
              <option value="Dmileage">{{ 'advertisement-list.filters.mileageHighToLow' | translate }}</option>
              <option value="Dyear">{{ 'advertisement-list.filters.yearLowToHigh' | translate }}</option>
              <option value="Ayear">{{ 'advertisement-list.filters.yearHighToLow' | translate }}</option>
            </select>
            <div class="d-none d-md-block border-end border" style="height: 1.25rem;"></div>
            <!-- Favorites Button - Shown on larger screens as before -->
            <div class="d-none d-sm-block">
              <div [routerLink]="['/favorieten']" class="fw-bold text opacity-70 text-nowrap ps-md-4">
                <i class="fi-heart me-2" [ngStyle]="{'color': favoritesService.count() > 0 ? 'red' : 'inherit'}"></i>
                <a href="javascript:void(0);" class="align-middle">{{ 'advertisement-list.filters.favorites' | translate
                  }}
                  ({{favoritesService.count()}})</a>
              </div>
            </div>
          </div>

          <div class="d-none d-sm-flex">
            <a (click)="viewType = 'list'" class="nav-link nav-link px-2" [class.active]="viewType === 'list'"
              placement="top" ngbTooltip="{{ 'advertisement-list.view.list' | translate }}">
              <i class="fi-list"></i>
            </a>
            <a (click)="viewType = 'gallery'" class="nav-link nav-link px-2" [class.active]="viewType === 'gallery'"
              placement="top" ngbTooltip="{{ 'advertisement-list.view.gallery' | translate }}">
              <i class="fi-grid"></i>
            </a>
          </div>
        </div>

        <!-- Favorites Button - Shown on mobile in full width -->
        <div class="d-sm-none w-100 pb-4 mb-2">
          <div [routerLink]="['/favorieten']" class="fw-bold text opacity-70 text-center">
            <i class="fi-heart me-2" [ngStyle]="{'color': favoritesService.count() > 0 ? 'red' : 'inherit'}"></i>
            <a href="javascript:void(0);" class="align-middle">{{ 'advertisement-list.filters.favorites' | translate }}
              ({{favoritesService.count()}})</a>
          </div>
        </div>

        <div class="card bg-light mb-3" *ngIf="customFilter.length > 0">
          <div class="card-body d-flex align-items-center justify-content-between p-2">
            <span style="font-size: 0.95rem;">{{ 'advertisement-list.filters.activeFilter' | translate }}</span>
            <button (click)="removeAllFilters()" type="button" class="btn btn-secondary btn-sm rounded-pill"
              style="font-size: 0.85rem;">{{ 'advertisement-list.filters.resetAllFilters' | translate }}</button>
          </div>
        </div>

        <!-- Items (list)-->
        <section *ngIf="viewType === 'list'">
          <div class="col-12">
            <ngb-alert *ngIf="advertisements.length === 0" [dismissible]="false" type="accent"
              class="mt-3 alert alert-accent d-flex">
              <i class="fi-bell me-2 me-sm-3 lead"></i>
              <div>{{ 'advertisement-list.noResults' | translate }}</div>
            </ngb-alert>
          </div>

          <div class="card card-hover card-horizontal mb-4" *ngFor="let advertisement of advertisements"
            (click)="navigateToAdvertisement(advertisement.id)">
            <div class="tns-carousel-wrapper card-img-top card-img-hover">
              <a class="img-overlay"></a>
              <div *ngIf="advertisement.vehicle.hasValidOdometer === true"
                class="position-absolute start-0 top-0 pt-2 ps-2">
                <img class="d-table mb-1 bg-white rounded" src="../../../assets/images/nap-check.png" alt="NAP Check"
                  width="60">
              </div>

              <button (click)="addToFavorites(advertisement)"
                *ngIf="favoritesService.isSaved(advertisement.id) === false"
                class="btn btn-icon btn-light btn-xs text-primary rounded-circle position-absolute end-0 top-0 mt-3 me-3"
                type="button" placement="left" ngbTooltip="{{ 'advertisement-list.compare' | translate }}">
                <i class="fi-heart"></i>
              </button>

              <button (click)="removeFromFavorites(advertisement)"
                *ngIf="favoritesService.isSaved(advertisement.id) === true"
                class="btn btn-icon btn-danger btn-xs text-white rounded-circle position-absolute end-0 top-0 mt-3 me-3"
                type="button" placement="left" ngbTooltip="{{ 'advertisement-list.removeCompare' | translate }}">
                <i class="fi-heart"></i>
              </button>

              <ngx-slick-carousel class="carousel">
                <div class="slide">
                  <img [src]="advertisement.image" class="card-img" alt="Image">
                </div>
              </ngx-slick-carousel>
            </div>

            <div class="card-body">
              <div class="d-flex align-items-center justify-content-between pb-1"><span
                  class="fs-sm text me-3">{{advertisement.vehicle.buildYear}}</span>
              </div>
              <h3 class="h6 mb-1"><a class="nav-link"
                  routerLink="/occasion/{{advertisement.id}}">{{advertisement.vehicle.make}}
                  {{advertisement.vehicle.model}} {{advertisement.vehicle.type}}</a></h3>

              <div class="text-primary fw-bold mb-1">
                <span class="text-danger" *ngIf="advertisement.consumerPrice == null">
                  {{ 'advertisement-list.sold' | translate }}
                </span>
                <ng-container *ngIf="advertisement.consumerPrice != null">
                  <span *ngIf="advertisement.consumerPrice.formerAmount != null"
                    class="text-muted text-decoration-line-through">
                    {{advertisement.consumerPrice.formerAmount | currencyFormat}}
                  </span>
                  <span *ngIf="advertisement.consumerPrice.formerAmount == null">
                    {{advertisement.consumerPrice.amount | currencyFormat}}
                  </span>
                  <span *ngIf="advertisement.consumerPrice.formerAmount != null" class="ms-2 text-success">
                    {{advertisement.consumerPrice.amount | currencyFormat}}
                  </span>
                </ng-container>
              </div>

              <div class="fs-sm text opacity-70">
                <span *ngFor="let accessory of advertisement.vehicle.accessories?.slice(0, 8)"
                  class="badge bg-secondary me-1 mb-1">
                  {{ accessory }}
                </span>
              </div>

              <div class="mt-2">
                <div class="row g-2">
                  <div class="col me-sm-1">
                    <div class="bg-primary rounded text-center w-100 p-1"><i
                        class="fi-dashboard d-block h6 text-light mb-0 mx-center"></i><span
                        class="fs-xs text-light">{{advertisement.vehicle.odometer | number}}
                        {{advertisement.vehicle.odometerUnit}}</span></div>
                  </div>
                  <div class="col me-sm-1">
                    <div class="bg-primary rounded text-center w-100 p-1"><i
                        class="fi-gearbox d-block h6 text-light mb-0 mx-center"></i><span
                        class="fs-xs text-light">{{advertisement.vehicle.transmissionType}}</span></div>
                  </div>
                  <div class="col me-sm-1">
                    <div class="bg-primary rounded text-center w-100 p-1"><i
                        class="fi-petrol d-block h6 text-light mb-0 mx-center"></i><span
                        class="fs-xs text-light">{{advertisement.vehicle.fuelType}}</span></div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>

        <!-- Items (gallery)-->
        <section *ngIf="viewType === 'gallery'">
          <div class="col-12">
            <ngb-alert *ngIf="advertisements.length === 0" [dismissible]="false" type="accent"
              class="mt-3 alert alert-accent d-flex">
              <i class="fi-bell me-2 me-sm-3 lead"></i>
              <div>{{ 'advertisement-list.noResults' | translate }}</div>
            </ngb-alert>
          </div>

          <div *ngFor="let advertisement of advertisements" (click)="navigateToAdvertisement(advertisement.id)">
            <div class="col-sm-12 mb-4">
              <div class="card card-light card-hover h-100">
                <div class="tns-carousel-wrapper card-img-top card-img-hover"><a class="img-overlay"></a>
                  <div *ngIf="advertisement.vehicle.hasValidOdometer === true"
                    class="position-absolute start-0 top-0 pt-2 ps-2">
                    <img class="d-table mb-1 bg-white rounded" src="../../../assets/images/nap-check.png"
                      alt="NAP Check" width="60">
                  </div>

                  <button (click)="addToFavorites(advertisement)"
                    *ngIf="favoritesService.isSaved(advertisement.id) === false"
                    class="btn btn-icon btn-light btn-xs text-primary rounded-circle position-absolute end-0 top-0 mt-3 me-3"
                    type="button" placement="left" ngbTooltip="{{ 'advertisement-list.compare' | translate }}">
                    <i class="fi-heart"></i>
                  </button>

                  <button (click)="removeFromFavorites(advertisement)"
                    *ngIf="favoritesService.isSaved(advertisement.id) === true"
                    class="btn btn-icon btn-danger btn-xs text-primary rounded-circle position-absolute end-0 top-0 mt-3 me-3"
                    type="button" placement="left" ngbTooltip="{{ 'advertisement-list.removeCompare' | translate }}">
                    <i class="fi-heart"></i>
                  </button>

                  <ngx-slick-carousel class="carousel">
                    <div class="slide">
                      <img [src]="advertisement.image" class="card-img" alt="Image">
                    </div>
                  </ngx-slick-carousel>
                </div>

                <div class="card-body">
                  <div class="d-flex align-items-center justify-content-between pb-1"><span
                      class="fs-sm text me-3">{{advertisement.vehicle.buildYear}}</span>
                  </div>
                  <h3 class="h6 mb-1"><a class="nav-link"
                      routerLink="/occasion/{{advertisement.id}}">{{advertisement.vehicle.make}}
                      {{advertisement.vehicle.model}} {{advertisement.vehicle.type}}</a></h3>

                  <div class="text-primary fw-bold mb-1">
                    <span class="text-danger" *ngIf="advertisement.consumerPrice == null">
                      {{ 'advertisement-list.sold' | translate }}
                    </span>
                    <ng-container *ngIf="advertisement.consumerPrice != null">
                      <span *ngIf="advertisement.consumerPrice.formerAmount != null"
                        class="text-muted text-decoration-line-through">
                        {{advertisement.consumerPrice.formerAmount | currencyFormat}}
                      </span>
                      <span *ngIf="advertisement.consumerPrice.formerAmount == null">
                        {{advertisement.consumerPrice.amount | currencyFormat}}
                      </span>
                      <span *ngIf="advertisement.consumerPrice.formerAmount != null" class="text-success ms-2">
                        {{advertisement.consumerPrice.amount | currencyFormat}}
                      </span>
                    </ng-container>
                  </div>

                  <div class="fs-sm text opacity-70">
                    <span *ngFor="let accessory of advertisement.vehicle.accessories?.slice(0, 8)"
                      class="badge bg-secondary me-1 mb-1">
                      {{ accessory }}
                    </span>
                  </div>

                  <div class="mt-2">
                    <div class="row g-2">
                      <div class="col me-sm-1">
                        <div class="bg-primary rounded text-center w-100 p-1"><i
                            class="fi-dashboard d-block h6 text-light mb-0 mx-center"></i><span
                            class="fs-sm text-light">{{advertisement.vehicle.odometer | number}}
                            {{advertisement.vehicle.odometerUnit}}</span></div>
                      </div>
                      <div class="col me-sm-1">
                        <div class="bg-primary rounded text-center w-100 p-1"><i
                            class="fi-gearbox d-block h6 text-light mb-0 mx-center"></i><span
                            class="fs-sm text-light">{{advertisement.vehicle.transmissionType}}</span></div>
                      </div>
                      <div class="col">
                        <div class="bg-primary rounded text-center w-100 p-1"><i
                            class="fi-petrol d-block h6 text-light mb-0 mx-center"></i><span
                            class="fs-sm text-light">{{advertisement.vehicle.fuelType}}</span></div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>

        <!-- Sorting + Pagination-->
        <div class="d-flex align-items-center justify-content-between py-2">
          <div class="d-flex align-items-center me-sm-4">
            <label class="fs-sm text me-2 pe-1 text-nowrap" for="sorting2"><i class="fi-arrows-sort mt-n1 me-2"></i>{{
              'advertisement-list.filters.sort' | translate }}</label>
            <select class="form-select form-select-sm me-sm-4" id="sorting1" (change)="sortChange($event)">
              <option value="">{{ 'advertisement-list.filters.select' | translate }}</option>
              <option value="Aprice">{{ 'advertisement-list.filters.priceLowToHigh' | translate }}</option>
              <option value="Dprice">{{ 'advertisement-list.filters.priceHighToLow' | translate }}</option>
              <option value="Amileage">{{ 'advertisement-list.filters.mileageLowToHigh' | translate }}</option>
              <option value="Dmileage">{{ 'advertisement-list.filters.mileageHighToLow' | translate }}</option>
              <option value="Dyear">{{ 'advertisement-list.filters.yearLowToHigh' | translate }}</option>
              <option value="Ayear">{{ 'advertisement-list.filters.yearHighToLow' | translate }}</option>
            </select>
            <div class="d-none d-md-block border-end border" style="height: 1.25rem;"></div>
            <div class="d-none d-sm-block fw-bold text opacity-70 text-nowrap ps-md-4"><i
                class="fi-heart me-2"></i><span class="align-middle">{{ 'advertisement-list.filters.favorites' |
                translate }} ({{favoritesService.count()}})</span>
            </div>
          </div>

          <nav aria-label="Pagination" *ngIf="advertisementPaginator">
            <ul class="pagination mb-0">
              <!-- Loop through each page -->
              <ng-container *ngFor="let page of getPaginationArray()">
                <!-- Add 1 to page index to start page numbering from 1 -->
                <li (click)="navigateToPage(page)" class="page-item d-sm-block" [class.active]="currentPage === page">
                  <a class="page-link" href="javascript:void(0);">{{ page }}</a>
                </li>
              </ng-container>
              <!-- Display the Next button -->
              <li class="page-item">
                <a class="page-link" (click)="nextPage()" href="javascript:void(0);" aria-label="Next">
                  <i class="fi-chevron-right"></i>
                </a>
              </li>
            </ul>
          </nav>
        </div>
      </div>
    </div>
  </div>
</div>

<button class="btn btn-primary btn-sm w-100 rounded-0 fixed-bottom d-lg-none" type="button" data-bs-toggle="offcanvas"
  data-bs-target="#filters-sidebar" (click)="filterSidebar()">{{ 'advertisement-list.filters.button' | translate
  }}</button>
<div class="vertical-overlay" (click)="sidebarHide()"></div>