import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { MainComponent } from './pages/main/main.component';
import { AdvertisementDetailComponent } from './pages/advertisement-detail/advertisement-detail.component';
import { AdvertisementListComponent } from './pages/advertisement-list/advertisement-list.component';
import { ReviewsComponent } from './pages/reviews/reviews.component';
import { OverAutoNetComponent } from './pages/over-auto-net/over-auto-net.component';
import { ContactComponent } from './pages/contact/contact.component';
import { FavorietenComponent } from './pages/favorieten/favorieten.component';
import { AutoVerkopenComponent } from './pages/auto-verkopen/auto-verkopen.component';
import { PrivacyverklaringComponent } from './pages/privacyverklaring/privacyverklaring.component';
import { AutoInruilenComponent } from './pages/auto-inruilen/auto-inruilen.component';
import { NotFoundComponent } from './pages/404/404.component';

const routes: Routes = [
  { path: '', component: MainComponent },
  { path: 'occasion/:id', component: AdvertisementDetailComponent },
  
  { path: 'occasions', component: AdvertisementListComponent },
  { path: 'occasions/:vehicletype', component: AdvertisementListComponent },
  { path: 'occasions/model/:model', component: AdvertisementListComponent },
  { path: 'occasions/merk/:make', component: AdvertisementListComponent },
  { path: 'occasions/merk/:make/model/:model', component: AdvertisementListComponent },

  { path: 'reviews', component: ReviewsComponent },
  { path: 'over-auto-net', component: OverAutoNetComponent },
  { path: 'contact', component: ContactComponent },
  { path: 'favorieten', component: FavorietenComponent },
  { path: 'privacyverklaring', component: PrivacyverklaringComponent },

  { path: 'auto-inruilen', component: AutoInruilenComponent },
  { path: 'auto-verkopen', component: AutoVerkopenComponent },
  { path: 'verkopen', component: AutoVerkopenComponent },
  { path: 'inruilen/:advertisementId', component: AutoVerkopenComponent },
  { path: 'inruilvoorstel/received/:requestId/token/:token', component: AutoVerkopenComponent },

  { path: 'nieuwsbrief/:action/:email/hash/:hash', component: MainComponent },

  { path: '**', component: NotFoundComponent }, // Wildcard route for 404 page
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
